@import "../../styles/common";
.container {
    &__skeleton {
        background-color: #eee;
        background-image: linear-gradient( 90deg,#eee,#f5f5f5,#eee );
        background-size: 200px 100%;
        background-repeat: no-repeat;
        border-radius: 4px;
        display: inline-block;
        height: 100%;
        width: 100%;
        animation: loading 2.0s ease-in-out infinite;
    }

    &__loadingText {
        display: flex;
        justify-content: center;
    }

}

@keyframes loading {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}