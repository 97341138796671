@use "../../styles/common";

$action-border: 1px solid common.$ct-gray-200;
$side-padding: 16px;

.messageItem {
  display: flex;
  justify-content: flex-start;

  &_out {
    justify-content: flex-end;
  }

  &__body {
    max-width: 80%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__text {
    padding: $side-padding;
    background-color: common.$ct-gray-175;
    border-radius: 5px 5px 5px 0;
    align-self: flex-start;
  }

  &_out &__text {
    :global(.ProseMirror) {
      color: common.$white;
    }
    color: common.$white;
    background-color: var(--primary);
    border-radius: 5px 5px 0 5px;
    align-self: flex-end;

    > a {
      color: common.$white;
    }
  }

  &__section {
    margin-top: 8px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__actions {
    border: $action-border;
    border-radius: 0 5px 5px 5px;
  }

  &_out &__actions {
    border-radius: 5px 0 5px 5px
  }

  &__action {
    width: 100%;
    border-bottom: $action-border;
    border-radius: 0;
    padding: 10px $side-padding;
    text-align: center;

    &:last-child {
      border-bottom: none;
    }
  }

  &__attachment {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__footer {
    display: flex;
    color: common.$ct-gray-650;
    font-size: common.$font-size-base * 0.86;
    padding-top: 6px;
    align-items: center;
    justify-content: flex-start;

    &_out {
      justify-content: flex-end;
    }
  }

  &__info {
    margin-right: 6px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__icon {
    margin-top: 4px;

    &_operator {
      color: var(--primary);
      font-size: 16px;
    }
  }
}